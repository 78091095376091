import request from '../utils/request'
import baseUrl from './baseUrl'

export const seckillInfoList = (data) => request({
  url: baseUrl + '/seckillInfo/selectForBack',
  method: 'POST',
  data
})
export const addOrEditSeckillInfo = (data) => request({
  url: baseUrl + '/seckillInfo/add',
  method: 'POST',
  data
})
export const seckillInfoDetail = (data) => request({
  url: baseUrl + '/seckillInfo/selectForId',
  method: 'POST',
  data
})
export const seckillInfoInvalid = (data) => request({
  url: baseUrl + '/seckillInfo/modify',
  method: 'POST',
  data
})
//删除秒杀
export const delSeckillInfo = (data) => request({
  url: baseUrl + '/seckillInfo/deleteById',
  method: 'POST',
  data
})
//检查是否可以升级
export const selectIsUpgrade = (data) => request({
  url: baseUrl + '/seckillInfo/selectIsUpgrade',
  method: 'POST',
  data
})
//选择产品时验证
export const isActivityKill = (data) => request({
  url: baseUrl + '/activity/selectIsEnable',
  method: 'POST',
  data
})
// 升级列表 
export const seckillUpgradeList = (data) => request({
  url: baseUrl + '/seckillUpgrade/selectForBack',
  method: 'POST',
  data
})
//添加升级套餐
export const addSeckillUpgrade = (data) => request({
  url: baseUrl + '/seckillUpgrade/add',
  method: 'POST',
  data
})
//删除升级套餐
export const delSeckillUpgrade = (data) => request({
  url: baseUrl + '/seckillUpgrade/deleteById',
  method: 'POST',
  data
})
//根据活动查询可升级产品  /seckillUpgrade/selectProdInfo
export const selectProdInfo = (data) => request({
  url: baseUrl + '/seckillUpgrade/selectProdInfo',
  method: 'POST',
  data
})
//修改排序 
export const changeSort = (data) => request({
  url: baseUrl + '/seckillInfo/modifyDept',
  method: 'POST',
  data
})
// 预售
export const getPresaleList = (data) => request({
  url: baseUrl + '/presaleInfo/selectForBack',
  method: 'POST',
  data
})
export const getPresaleListAdd = (data) => request({
  url: baseUrl + '/presaleInfo/add',
  method: 'POST',
  data
})
export const getPresaleListDetails = (data) => request({
  url: baseUrl + '/presaleInfo/selectForId',
  method: 'POST',
  data
})
export const getPresaleListSort= (data) => request({
  url: baseUrl + '/presaleInfo/modifyDept',
  method: 'POST',
  data
})
export const getPresaleListUpdata = (data) => request({
  url: baseUrl + '/presaleInfo/modify',
  method: 'POST',
  data
})
export const getPresaleListDel = (data) => request({
  url: baseUrl + '/presaleInfo/deleteById',
  method: 'POST',
  data
})
export const getPresaleListUp = (data) => request({
  url: baseUrl + '/presaleInfo/selectIsUpgrade',
  method: 'POST',
  data
})
export const getPresaleListUpLs = (data) => request({
  url: baseUrl + '/presaleUpgrade/selectForBack',
  method: 'POST',
  data
})
export const getPresaleListUpAdd = (data) => request({
  url: baseUrl + '/presaleUpgrade/add',
  method: 'POST',
  data
})
export const getPresaleListUpDel = (data) => request({
  url: baseUrl + '/presaleUpgrade/deleteById',
  method: 'POST',
  data
})
export const getPresaleListUpInfo = (data) => request({
  url: baseUrl + '/presaleUpgrade/selectProdInfo',
  method: 'POST',
  data
})
export const room = (data) => request({
  url: baseUrl + '/room/selectForPresale',
  method: 'POST',
  data
})
export const ticket = (data) => request({
  url: baseUrl + '/ticket/selectForPresale',
  method: 'POST',
  data
})
export const travel = (data) => request({
  url: baseUrl + '/travel/selectForPresale',
  method: 'POST',
  data
})

// 超值礼包 - 分类列表
//查询
export const getGiftCateList = (data) => request({
  url: baseUrl + '/giftCate/selectForBack',
  method: 'POST',
  data
})
// 添加/修改
export const addGiftCate = (data) => request({
  url: baseUrl + '/giftCate/add',
  method: 'POST',
  data
})
// 排序
export const modifyDeptGiftCateDept = (data) => request({
  url: baseUrl + '/giftCate/modifyDept',
  method: 'POST',
  data
})
// 上下架
export const modifyGiftCateStatus = (data) => request({
  url: baseUrl + '/giftCate/modify',
  method: 'POST',
  data
})
// 删除
export const deleteGiftCate = (data) => request({
  url: baseUrl + '/giftCate/deleteById',
  method: 'POST',
  data
})
// 查看日志
export const selectLogGiftCate = (data) => request({
  url: baseUrl + '/giftCate/selectLog',
  method: 'POST',
  data
})
// 超值礼包 - 礼包列表
// 查询所有分类列表
export const getClassifyList = (data) => request({
  url: baseUrl + '/giftCate/selectAll',
  method: 'POST',
  data
})
// 查询
export const getPackageList = (data) => request({
  url: baseUrl + '/pack/selectForBack',
  method: 'POST',
  data
})
//添加
export const addPackage = (data) => request({
  url: baseUrl + '/pack/add',
  method: 'POST',
  data
})
//启用/失效
export const modifyPackageStatus = (data) => request({
  url: baseUrl + '/pack/modify',
  method: 'POST',
  data
})
//启用/失效
export const modifyDeptPackageStatus = (data) => request({
  url: baseUrl + '/pack/modifyDept',
  method: 'POST',
  data
})
//查看详情
export const getPackageDetail= (data) => request({
  url: baseUrl + '/pack/selectForId',
  method: 'POST',
  data
})
//删除
export const deletePackage= (data) => request({
  url: baseUrl + '/pack/deleteById',
  method: 'POST',
  data
})
// 礼券详情
// 列表-查询
export const getPackProdList= (data) => request({
  url: baseUrl + '/packProd/selectForBack',
  method: 'POST',
  data
})
// 列表-添加/修改
export const addPackProd= (data) => request({
  url: baseUrl + '/packProd/add',
  method: 'POST',
  data
})
// 列表-查看详情
export const getPackProdDetail= (data) => request({
  url: baseUrl + '/packProd/selectForId',
  method: 'POST',
  data
})
// 列表-删除
export const deletePackProd= (data) => request({
  url: baseUrl + '/packProd/deleteById',
  method: 'POST',
  data
})
// 列表-上架/下架
export const modifyPackProdStatus= (data) => request({
  url: baseUrl + '/packProd/modify',
  method: 'POST',
  data
})
// 礼券详情-升级差价
export const getGiftPackageListUpLs = (data) => request({
  url: baseUrl + '/packProdUpgrade/selectForBack',
  method: 'POST',
  data
})
// 根据活动查询可升级产品
export const getGiftPackageListUpInfo = (data) => request({
  url: baseUrl + '/packProdUpgrade/selectProdInfo',
  method: 'POST',
  data
})
// 添加
export const getGiftPackageListUpAdd = (data) => request({
  url: baseUrl + '/packProdUpgrade/add',
  method: 'POST',
  data
})
// 删除
export const getGiftPackageListUpDel = (data) => request({
  url: baseUrl + '/packProdUpgrade/deleteById',
  method: 'POST',
  data
})
// 超值礼包查看日志
export const getGiftPackageLogList = (data) => request({
  url: baseUrl + '/packLog/selectForBack',
  method: 'POST',
  data
})
// 超值礼包查看日志详情
export const getGiftPackageLogDetail = (data) => request({
  url: baseUrl + '/packLog/selectForId',
  method: 'POST',
  data
})
// 超值礼包查看日志
export const getGiftPackageProdLogList = (data) => request({
  url: baseUrl + '/packProdLog/selectForBack',
  method: 'POST',
  data
})
// 超值礼包查看日志详情
export const getGiftPackageProdLogDetail = (data) => request({
  url: baseUrl + '/packProdLog/selectForId',
  method: 'POST',
  data
})


// 万小店查询超值礼包列表
export const getGiftPackageListWXD = (data) => request({
  url: baseUrl + '/pack/selectForProfit',
  method: 'POST',
  data
})
export const wanlvSeckillShow = (data) => request({
  url: baseUrl + '/seckillInfo/modifyWlShow',
  method: 'POST',
  data
})

export const getPackList = (data) => request({
  url: baseUrl + '/pack/selectForGroupBuy',
  method: 'POST',
  data
})
export const wanlvPackShow = (data) => request({
  url: baseUrl + '/pack/updateList',
  method: 'POST',
  data
})
export const giftCodeImgWx = (data) => request({
  url: baseUrl + '/pack/sharePack',
  method: 'POST',
  data
})
export const logList = (data) => request({
  url: baseUrl + '/seckillLog/selectForBack',
  method: 'POST',
  data
})
export const logDetails = (data) => request({
  url: baseUrl + '/seckillLog/selectForId',
  method: 'POST',
  data
})
